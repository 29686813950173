import {
  addAddress,
  changePassword,
  confirm,
  getAccount,
  login,
  logout,
  register,
  removeAddress,
  requestConfirmationEmail,
  requestPasswordReset,
  resetPassword,
  setDefaultBillingAddress,
  setDefaultShippingAddress,
  update,
  updateAddress,
  updateCustomerDetails
} from '../../actions/account'
import { createSession } from '../../actions/adyen'
import {
  addCoupon,
  addItem,
  cartItems,
  checkout,
  getOrder,
  getPaymentMethods,
  getProjectSettings,
  getShippingMethods,
  getAvailableShippingMethods,
  getShippingSettings,
  orderHistory,
  redeemDiscountCode,
  refreshCart,
  removeDiscountCode,
  removeItem,
  setPaymentMethod,
  setShippingMethod,
  syncCartPayment,
  triggerPayment,
  unsetCartSession,
  updateCart,
  updateItem,
  updatePatientDetails,
} from '../../actions/cart'
import { getTaxRates } from '../../actions/tax'
import { getVouchers, redeemVoucher, validateVoucher } from '../../actions/voucherify'
import { getWishlist, addToWishlist, removeLineItem, updateLineItem } from '../../actions/wishlist'
import { UseAccount } from './UseAccount'
import { UseAdyen } from './UseAdyen'
import { UseCart } from './UseCart'
import { UseTax } from './UseTax'
import { UseVoucherify } from './UseVoucherify'
import { UseWishlist } from './UseWishlist'

export interface FrontasticState {
  useCart: UseCart;
  useAccount: UseAccount;
  useWishlist: UseWishlist;
  useAdyen: UseAdyen;
  useVoucherify: UseVoucherify;
  useTax: UseTax
}

export const getFrontasticState = (): FrontasticState => {
  return {
    useCart: {
      ...cartItems(),
      addItem,
      updateCart,
      refreshCart,
      removeItem,
      updateItem,
      getPaymentMethods,
      setPaymentMethod,
      getShippingMethods,
      getAvailableShippingMethods,
      setShippingMethod,
      checkout,
      orderHistory,
      getOrder,
      getProjectSettings,
      redeemDiscountCode,
      removeDiscountCode,
      addCoupon,
      updatePatientDetails,
      unsetCartSession,
      triggerPayment,
      syncCartPayment,
      getShippingSettings,
    },
    useAccount: {
      ...getAccount(),
      login,
      logout,
      register,
      confirm,
      requestConfirmationEmail,
      changePassword,
      requestPasswordReset,
      resetPassword,
      update,
      addAddress,
      updateAddress,
      removeAddress,
      setDefaultBillingAddress,
      setDefaultShippingAddress,
      updateCustomerDetails
    },
    useWishlist: {
      ...getWishlist(),
      addToWishlist,
      removeLineItem,
      updateLineItem,
    },
    useAdyen: {
      createSession,
    },
    useVoucherify: {
      getVouchers,
      redeemVoucher,
      validateVoucher,
    },
    useTax: {
      getTaxRates
    }
  }
}
