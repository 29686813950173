import useSWR, { mutate } from 'swr';
import { REMEMBER_ME } from 'helpers/constants/localStorage';
import { revalidateOptions } from 'frontastic';
import { fetchApiHub } from 'frontastic/lib/fetch-api-hub';
export const getAccount = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const result = useSWR('/action/account/getAccount', fetchApiHub, revalidateOptions);
    const account = result.data?.account || result.data;
    if (account?.accountId && account?.confirmed)
        return { account, isValidating: result.isValidating, loggedIn: true };
    return {
        loggedIn: false,
        isValidating: result.isValidating,
        account: undefined,
        error: result.error,
    };
};
export const login = async (email, password, remember) => {
    const payload = {
        email,
        password,
    };
    if (remember)
        window.localStorage.setItem(REMEMBER_ME, '1');
    const res = await fetchApiHub('/action/account/login', { method: 'POST' }, payload);
    await mutate('/action/account/getAccount', res);
    await mutate('/action/cart/getCart');
    // await mutate('/action/wishlist/getWishlist')
    return res;
};
export const logout = async () => {
    window.localStorage.removeItem(REMEMBER_ME);
    const res = await fetchApiHub('/action/account/logout', { method: 'POST' });
    await mutate('/action/account/getAccount', res);
    await mutate('/action/cart/getCart');
    // await mutate('/action/wishlist/getWishlist')
    return res;
};
export const register = async (account) => {
    const response = await fetchApiHub('/action/account/register', { method: 'POST' }, account);
    return response;
};
export const confirm = async (token) => {
    const res = await fetchApiHub('/action/account/confirm', { method: 'POST' }, { token });
    await mutate('/action/account/getAccount', res);
    return res;
};
export const requestConfirmationEmail = async (email, password) => {
    const payload = {
        email,
        password,
    };
    const res = await fetchApiHub('/action/account/requestConfirmationEmail', { method: 'POST' }, payload);
    return res;
};
export const changePassword = async (oldPassword, newPassword) => {
    return await fetchApiHub('/action/account/password', { method: 'POST' }, { oldPassword, newPassword });
};
export const requestPasswordReset = async (email) => {
    const payload = {
        email,
    };
    return await fetchApiHub('/action/account/requestReset', { method: 'POST' }, payload);
};
export const resetPassword = async (token, newPassword) => {
    const res = await fetchApiHub('/action/account/reset', { method: 'POST' }, { token, newPassword });
    await mutate('/action/account/getAccount', res);
    return res;
};
export const update = async (account) => {
    const res = await fetchApiHub('/action/account/update', { method: 'POST' }, account);
    await mutate('/action/account/getAccount', res);
    return res;
};
export const addAddress = async (address) => {
    const res = await fetchApiHub('/action/account/addAddress', { method: 'POST' }, address);
    await mutate('/action/account/getAccount', res);
    return res;
};
export const updateAddress = async (address) => {
    const res = !address?.addressId
        ? await fetchApiHub('/action/account/addAddress', { method: 'POST' }, address)
        : await fetchApiHub('/action/account/updateAddress', { method: 'POST' }, address);
    await mutate('/action/account/getAccount');
    return res;
};
export const removeAddress = async (addressId) => {
    const res = await fetchApiHub('/action/account/removeAddress', { method: 'POST' }, { addressId });
    await mutate('/action/account/getAccount', res);
    return res;
};
export const setDefaultBillingAddress = async (addressId) => {
    const res = await fetchApiHub('/action/account/setDefaultBillingAddress', { method: 'POST' }, { addressId });
    await mutate('/action/account/getAccount', res);
    return res;
};
export const setDefaultShippingAddress = async (addressId) => {
    const res = await fetchApiHub('/action/account/setDefaultShippingAddress', { method: 'POST' }, { addressId });
    await mutate('/action/account/getAccount', res);
    return res;
};
export const updateCustomerDetails = async (patientData) => {
    const payload = {
        firstName: patientData.patientFirstName,
        lastName: patientData.patientLastName,
        customerPhone: `(${patientData.patientCountryPhoneCode}) ${patientData.patientPhone}`,
    };
    const res = await fetchApiHub('/action/account/updateCustomerDetails', { method: 'POST' }, payload);
    await mutate('/action/account/getAccount', res);
    return res;
};
