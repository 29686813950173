import { VoucherListResponse, VoucherRedeemResponse, VoucherValidationResponse } from '@Types/voucherify/Voucher';
import { fetchApiHub } from "frontastic/lib/fetch-api-hub";

export const getVouchers = async (): Promise<VoucherListResponse> => {
  const res = await fetchApiHub('/action/voucherify/getVouchers', { method: 'POST' }, {})
  return res
};

export const redeemVoucher = async (code: string, accountId: string, orderId: string): Promise<VoucherRedeemResponse> => {
  const res = await fetchApiHub('/action/voucherify/redeemVoucher', { method: 'POST' }, { code, accountId, orderId })
  return res
}

export const validateVoucher = async (code: string, accountId: string): Promise<VoucherValidationResponse> => {
  const res = await fetchApiHub('/action/voucherify/validateVoucher', { method: 'POST' }, { code, accountId })
  return res
}