import React from 'react';
import type { AppProps } from 'next/app';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import Toaster from 'components/commercetools-ui/toaster';
import { PAYPAL_CLIENT_ID } from 'helpers/constants/envVariables';
import { appWithTranslation } from 'next-i18next';
import { FrontasticProvider } from 'frontastic';
import '../styles/themes/default.css';
import 'tailwindcss/tailwind.css';
import '../styles/app.scss';
import { UserProvider } from '@auth0/nextjs-auth0/client'

function FrontasticStarter({ Component, pageProps }: AppProps) {
  return (
    <FrontasticProvider>
      <UserProvider>
        <Component {...pageProps} />
        <Toaster />
      </UserProvider>
    </FrontasticProvider>
  );
}

export default appWithTranslation(FrontasticStarter);
